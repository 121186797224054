import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import DateDetail from '../components/dateDetail'
import moment from 'moment'
class Post extends Component {
  render() {
    const { data } = this.props.data.post
    const title = data.title.text
    const date = data.eventdate
    return (
      <Layout>
        <SEO
          title={`LACK | ${title} | ${moment(date).format('DD MMM YYYY')}`}
          keywords={[`techno`, `label`, `records`]}
        />
        <DateDetail title={title} date={date} paragraph={data.paragraph.html} />
      </Layout>
    )
  }
}

export default Post

export const postQuery = graphql`
  query Post($slug: String) {
    post: prismicDate(id: { eq: $slug }) {
      id
      data {
        eventdate
        title {
          text
        }
        paragraph {
          html
        }
      }
    }
  }
`
