import React from 'react'
import '../dateDetail/style.scss'
import moment from 'moment'

const DateDetail = props => (
  <>
    <div className="page">
      <h1>
        {props.title} - {moment(props.date).format('DD MMM YYYY')}{' '}
      </h1>

      <div
        className="desc"
        dangerouslySetInnerHTML={{ __html: props.paragraph }}
      />
    </div>
  </>
)

export default DateDetail
